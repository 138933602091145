<template>
    <block class="uk-breadcrumb">
        <li>
            <router-link to="/">Home</router-link>
        </li>
        <li v-for="crumb of crumbs" :key="crumb" :crumb="crumb">
            <router-link  :to="crumb.path"
                          custom
                          v-slot="{ navigate, href, route }">
                <a @click="navigate"
                             type="default"
                             :showSlot="false"
                             v-if="!crumb.isLast">
                    {{crumb.label}}
                </a>
                <span type="default"
                      :showSlot="false"
                      v-if="crumb.isLast">
                    {{crumb.label}}
                </span>
            </router-link>


        </li>



    </block>
</template>

<script>
    export default {
        data: function () {
            return {

            };
        },
        computed : {
            crumbs () {
                let route = this.$router.currentRoute._value.fullPath;
                let parts = route.split('/');
                let crumbs = [];
                let tempPath = '';
                let count = 0;
                parts.forEach((part) => {
                    count ++;
                    if (part === '') {
                        return true;
                    }
                    tempPath = tempPath + '/' + part;
                    // todo: breadcrumbs translation
                    crumbs.push({
                        path: tempPath,
                        name: part,
                        label: utilities.ucFirst(part),
                        isLast : count >= parts.length,
                    })
                });


                return crumbs;
            }
        },
        created () {
            let route = this.$router.currentRoute._value.fullPath;
            //TODO: use useRoute composition instead
        }

    }
</script>

<style scoped lang="scss">

</style>
